
const appEnv = process.env.REACT_APP_APP_ENV !== undefined && process.env.REACT_APP_APP_ENV !== '' ? process.env.REACT_APP_APP_ENV : 'PROD'; // DEV, STG, PROD
const appMode = process.env.REACT_APP_APP_MODE !== undefined && process.env.REACT_APP_APP_DEBUG !== '' ? process.env.REACT_APP_APP_MODE : 'PERSONAL'; // PERSONAL, KIOSK, POS
const debugMode = process.env.REACT_APP_APP_DEBUG !== undefined && process.env.REACT_APP_APP_DEBUG === 'true';

export var logOutput = '';

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const logger = (str, force) => {
    if (typeof console === "object") {
        force = force || false;
        //if (ConfigGlobal.Debug() || force) {
            console.log(str);
        //}
    }
    if (isDebugMode()) {
        logOutput += "\n" + unixUtcTimeSec() + ": " + str;
        if (typeof str === "object") {
            for (let s in str) {
                logOutput += "\n - " + s+'='+str[s];
                if (typeof str[s] === "object") {
                    for (let o in str[s]) {
                        logOutput += "\n -- " + o+'='+str[s][o];
                    }
                }
            }
        }
    }
}

export const isDebugMode = () => {
    return debugMode;
}

export const getAppMode = () => {
    return appMode;
}
export const isAppModePersonal = () => {
    //return appMode === 'PERSONAL';
    return !isAppModeKiosk() && !isAppModePos();
}
export const isAppModeKiosk = () => {
    return appMode === 'KIOSK';
}
export const isAppModePos = () => {
    return appMode === 'POS';
}

export const clearLogOutput = () => {
    logOutput = unixUtcTimeSec() + ": log cleared";
}

export const nl2br = (str) => {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br />' + '$2');
}

export const getPopupResponsiveSettings = (isIos) => {
    return {
        xsmall: {
            display: isIos ? "center" : "top"
        },
        small: {
            display: "center"
        }
    }
}

export const isProduction = () => {
    return appEnv === 'PROD'; // process.env.NODE_ENV === 'production';
}

export const isCordova = () => {
    /*eslint-disable no-undef*/
    return (typeof cordova === 'object');
    /*eslint-enable no-undef*/
}

export const isGoogleMapsLoaded = () => {
    try {
        /*eslint-disable no-undef*/
        return (typeof google === 'object' && typeof google.maps === 'object');
        /*eslint-enable no-undef*/
    } catch (error) {
        logger(error);
        return false;
    }
}

export const isAppleSigninLoaded = () => {
    try {
        /*eslint-disable no-undef*/
        return isCordova() && (typeof window.cordova.plugins === 'object' && typeof window.cordova.plugins.SignInWithApple === 'object');
        /*eslint-enable no-undef*/
    } catch (error) {
        logger(error);
        return false;
    }
}

export const isGoogleSigninLoaded = () => {
    try {
        /*eslint-disable no-undef*/
        return isCordova() && (typeof window.plugins === 'object' && typeof window.plugins.googleplus === 'object');
        /*eslint-enable no-undef*/
    } catch (error) {
        logger(error);
        return false;
    }
}

export const openWindow = (url, target, features, usewkwebview) => {
    if (usewkwebview) {
        /*
        // don't think this is needed anymore
        if (features !== '') {
            features += ',';
        }
        features += 'usewkwebview=yes';
         */
    }
    return window.open(url, target, features);
}

export const isIframe = () => {
    return !isCordova() && (window.top !== window.self);
}

export const attachDeviceInfoToData = (data) => {
    //attaches device info to the array that is passed in
    try {
        if (typeof (navigator) !== 'undefined' && 'userAgent' in navigator) {
            data.device_useragent = navigator.userAgent;
        }
        //logger("|attachDeviceInfoToData| device_useragent="+data.device_useragent);
        /*eslint-disable no-undef*/
        if (isCordova() && typeof device !== 'undefined') {
            //logger('DEVICE');
            //logger(device);
            var device_keys = ['cordova', 'platform', 'uuid', 'version', 'model'];
            for (var i = 0; i < device_keys.length; i++) {
                var key = device_keys[i];
                if (device[key]) {
                    var data_key = 'device_' + key;
                    data[data_key] = device[key];
                    //logger("|attachDeviceInfoToData| "+data_key+'='+device[key]);
                }
            }
        }
        /*eslint-enable no-undef*/
    } catch (error) {
        logger(error);
    }
}

export const unixDatetimeToDate = (datetime) => {
    if (datetime) {
        const jsdate = new Date(datetime);
        // YYYY-MM-DD
        let date = jsdate.getFullYear()+'-'+`${jsdate.getMonth() + 1}`.padStart(2,0)+'-'+`${jsdate.getDate()}`.padStart(2,0);
        return date;
    }
    return null;
}

export const unixDatetimeToDateAuEng = (datetime) => {
    if (datetime) {
        const jsdate = new Date(datetime);
        // DD/MM/YYYY
        let date = `${jsdate.getDate()}`.padStart(2,0)+'/'+`${jsdate.getMonth() + 1}`.padStart(2,0)+'/'+jsdate.getFullYear();
        return date;
    }
    return null;
}

export const unixDatetimeToTime = (datetime) => {
    if (datetime) {
        const jsdate = new Date(datetime);
        // HH:mm
        let time = `${jsdate.getHours()}`.padStart(2,0)+':'+`${jsdate.getMinutes()}`.padStart(2,0);
        return time;
    }
    return null;
}

export const unixDatetimeToDateTime = (datetime) => {
    if (datetime) {
        const jsdate = new Date(datetime);
        // YYYY-MM-DD HH:mm
        let date = jsdate.getFullYear()+'-'+`${jsdate.getMonth() + 1}`.padStart(2,0)+'-'+`${jsdate.getDate()}`.padStart(2,0)+' '+`${jsdate.getHours()}`.padStart(2,0)+':'+`${jsdate.getMinutes()}`.padStart(2,0);
        return date;
    }
    return null;
}

export const unixUtcTimeSec = () => {
    return parseInt(new Date().getTime() / 1000, 10);
}

export const unixUtcTimeMs = () => {
    return parseInt(new Date().getTime(), 10);
}

export const unixUtcTimeOffsetMins = (offsetMins) => {
    let t = unixUtcTimeSec();
    t = t + (offsetMins*60);
    return t;
}

export const jsDateToday = () => {
    let d = new Date();
    d.setSeconds(0);
    d.setHours(0);
    d.setMinutes(0);
    d.setMilliseconds(0);
    return d;
}

export const jsDateTomorrow = () => {
    let d = jsDateToday();
    d.setDate(d.getDate() + 1);
    return d;
}
